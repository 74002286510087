import { Stack } from "@fluentui/react";
import {
  DocumentSearchRegular,
  TicketHorizontal20Regular,
  CircleFilled,
} from "@fluentui/react-icons";
import { Text, Dropdown, Option } from "@fluentui/react-components";

import { useCallback, useEffect, useState } from "react";
import { useAppClient } from "../../services/appClient";
import { ClipLoader } from "react-spinners";
import { ServiceNowTicket } from "../../../__generated__/graphql";
import { customTokens } from "../../../theme";
import { UpdateTicketForm } from "./DrawerForms/UpdateTicketForm";
import { useAuthenticationContext } from "../../../AuthenticationService/useAuthentication";

function NoTicketsView() {
  return (
    <>
      <Stack>
        <Stack.Item style={{ marginTop: "20px" }}>
          <Stack horizontal horizontalAlign="center">
            <DocumentSearchRegular fontSize={32} color="#7D7F86" />
          </Stack>
        </Stack.Item>
        <Stack.Item style={{ marginTop: "20px" }}>
          There are currently no tickets assigned to this user.
        </Stack.Item>
      </Stack>
    </>
  );
}

export function TicketDrawer() {
  const { user } = useAuthenticationContext();

  const snowUrl = user.client.clientSettings.openTicketUrl;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [acrData, setAcrData] = useState<ServiceNowTicket[]>();

  const { ticketData, ticketError, ticketLoading } = useAppClient();

  useEffect(() => {
    if (ticketData && ticketData.length) {
      setAcrData(ticketData);
    }
  }, [ticketData]);

  const handleOptionSelect = useCallback(
    (event: any, dt: any) => {
      const index = acrData
        ? acrData.findIndex((t: any) => t.number === dt.optionText)
        : 0;
      setSelectedIndex(index);
    },
    [acrData]
  );

  useEffect(() => {
    console.log("ACR DATA: ", acrData);
  }, [acrData]);

  if (ticketError) return <div>Error</div>;
  if (ticketLoading)
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        horizontal
        style={{ height: "80vh" }}
      >
        <ClipLoader color="#36d7b7" size={30} />
      </Stack>
    );

  const ticketOptions = acrData?.map((t: any) => {
    return (
      <Option
        style={{
          background: customTokens.drawerInsideColor,
        }}
        key={t.number}
        value={t.number}
        text={t.number}
        checkIcon={
          <CircleFilled
            color="#0070AD"
            fontSize={"8px"}
            style={{ marginLeft: "5px", marginRight: "5px" }}
          />
        }
      >
        <Stack>
          <Stack horizontal horizontalAlign="start">
            <TicketHorizontal20Regular />
            <Text size={200} style={{ marginLeft: "10px" }}>
              {t?.number}
            </Text>
          </Stack>
          {/* Max 20 characters */}
          <Stack horizontal>
            <Text size={200}>
              {t?.shortDescription.length >= 25
                ? t?.shortDescription.substr(0, 25) + " ..."
                : t?.shortDescription}
            </Text>
          </Stack>
        </Stack>
      </Option>
    );
  });

  return (
    <>
      {!acrData || (acrData && !acrData.length && <NoTicketsView />)}
      {acrData?.length && (
        <Stack
          horizontal
          horizontalAlign="center"
          style={{ marginTop: "20px" }}
        >
          <Dropdown
            button={
              <Stack>
                <Stack horizontal horizontalAlign="start">
                  <TicketHorizontal20Regular />
                  <Text size={200} style={{ marginLeft: "10px" }}>
                    {acrData[selectedIndex]?.number}
                  </Text>
                </Stack>
                {/* Max 20 characters */}
                <Stack horizontal>
                  <Text size={200}>
                    {String(acrData[selectedIndex]?.shortDescription).length &&
                    String(acrData[selectedIndex]?.shortDescription).length >=
                      25
                      ? String(acrData[selectedIndex]?.shortDescription).substr(
                          0,
                          25
                        ) + " ..."
                      : acrData[selectedIndex]?.shortDescription}
                  </Text>
                </Stack>
              </Stack>
            }
            style={{
              background: customTokens.drawerInsideColor,
              width: "95%",
              borderRadius: "8px",
              boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
              border: "none",
            }}
            defaultSelectedOptions={[
              ticketOptions ? ticketOptions[selectedIndex]?.props.value : 0,
            ]}
            onOptionSelect={handleOptionSelect}
          >
            {ticketOptions}
          </Dropdown>
        </Stack>
      )}
      <Stack
        styles={{
          root: { paddingTop: "23px", marginLeft: "1em", width: "120%" },
        }}
        tokens={{ childrenGap: 15 }}
      >
        <Stack.Item>
          <Stack horizontal horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Number:
            </Text>
            <Text
              size={200}
              style={{
                color: customTokens.drawerRightTextColor,
                // fontWeight: 600,
              }}
            >
              <a
                href={snowUrl
                  .replace(
                    "{ticket_table}",
                    acrData && acrData[selectedIndex]?.sysClassName
                  )
                  .replace(
                    "{ticket_number}",
                    acrData && acrData[selectedIndex]?.number
                  )}
                target="_blank"
                style={{ color: customTokens.drawerRightTextColor }}
              >
                {acrData ? acrData[selectedIndex]?.number : null}
              </a>
            </Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              State:
            </Text>
            <Text
              size={200}
              style={{ color: customTokens.drawerRightTextColor }}
            >
              {acrData ? acrData[selectedIndex]?.state : ""}
            </Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Last Modified:
            </Text>
            <Text
              size={200}
              style={{ color: customTokens.drawerRightTextColor }}
            >
              {acrData ? acrData[selectedIndex]?.sysUpdatedOn : ""}
            </Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Group:
            </Text>
            <Text
              size={200}
              style={{ color: customTokens.drawerRightTextColor }}
            >
              {acrData ? acrData[selectedIndex]?.assignmentGroup : ""}
            </Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Short description:
            </Text>
            <Text
              size={200}
              style={{
                overflowWrap: "break-word",
                marginTop: "5px",
                color: customTokens.drawerRightTextColor,
              }}
            >
              {acrData ? acrData[selectedIndex]?.shortDescription : ""}
            </Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Summary:
            </Text>
            <Text
              size={200}
              style={{
                overflowWrap: "break-word",
                marginTop: "5px",
                color: customTokens.drawerRightTextColor,
                width: "78%",
              }}
            >
              {acrData ? acrData[selectedIndex]?.description : ""}
            </Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontalAlign="start">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Last Update:
            </Text>
            <Text
              size={200}
              style={{
                marginTop: "5px",
                width: "20vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              {acrData ? acrData[selectedIndex]?.commentsAndWorkNotes : ""}
            </Text>
          </Stack>
        </Stack.Item>
        <UpdateTicketForm acrData={acrData} selectedIndex={selectedIndex} />
      </Stack>
    </>
  );
}
