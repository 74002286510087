import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  from,
  makeVar,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const msalInstance: any = new PublicClientApplication(msalConfig);
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

let client: any;

export const textBoxesPerConversation = makeVar<any>([]);
export const aiAssistPerConversation = makeVar<any>([]);

if (localStorage.getItem("token")) {
  localStorage.removeItem("token");
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    window.dispatchEvent(new Event("socket-event-error"));
  }
});

const WEBSOCKET_URL: string = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL.toString()
  : "wss://buddybot-dev.capgemini.com/graphql/";

const lookingGlassLink = new ApolloLink((operation, forward) => {
  console.log("operation called: ", operation.operationName);
  // console.log("Operation variables: ", operation.variables);
  return forward(operation);
});

export const wsLink = new GraphQLWsLink(
  createClient({
    url: WEBSOCKET_URL,
    // url: "ws://localhost:4617/graphql/",
    // url: "wss://buddybot-dev.capgemini.com/sb3/graphql/graphql/",
    lazy: false,
    on: {
      closed: (e) => {
        localStorage.removeItem("token");
      },
      error: (error) => {
        console.log("Error From The on -> Error message on root index");
        console.log(error);
        window.dispatchEvent(new Event("socket-event-error"));
      },
      connected: () => {
        console.log("Connected To Websocket");
        window.dispatchEvent(new Event("socket-connected"));
      },
    },
    shouldRetry: () => true,
    // keepAlive: 10000,

    retryAttempts: Infinity,
  })
);

client = new ApolloClient({
  link: from([errorLink, lookingGlassLink, wsLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          textBoxesPerConversation: {
            read() {
              return textBoxesPerConversation();
            },
          },
          aiAssistPerConversation: {
            read() {
              return aiAssistPerConversation();
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={client}>
    <App instance={msalInstance} />
  </ApolloProvider>
);
