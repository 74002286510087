import { Button, ProgressBar, Text } from "@fluentui/react-components";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Stack } from "@fluentui/react";
import { Document20Regular } from "@fluentui/react-icons";

enum IngesterType {
  DOCUMENT_INGESTER = "document_ingester",
  TICKET_INGESTER = "ticket_ingester",
  KBA_INGESTER = "kba_ingester",
}

interface FileFormat {
  name: string;
  format: string;
  chunk: string; // Base64 encoded chunk
  chunkIndex: number; // Order of the chunk
  totalChunks: number;
  ingesterType: IngesterType;
}

export function FileUpload() {
  // const [files, setFiles] = useState<File[]>([]);

  const handleFiles = (selectedFiles: FileList | null) => {
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) =>
    e.preventDefault();

  const handleFilePicker = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput?.click();
  };

  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});

  const CHUNK_SIZE = 1024 * 1024; // 1MB chunk size

  const MUTATION = gql`
    mutation ($fileChunk: FileFormat!) {
      uploadFileMutation(fileChunk: $fileChunk) {
        success
      }
    }
  `;

  const [mutate] = useMutation(MUTATION, {
    onCompleted: (data) => {
      console.log(data);
    },
  });

  // Convert a file slice (chunk) to base64
  const fileChunkToBase64 = (
    file: File,
    start: number,
    end: number
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const blob = file.slice(start, end);
      reader.readAsDataURL(blob);
      reader.onload = () => resolve((reader.result as string).split(",")[1]);
      reader.onerror = reject;
    });
  };

  const handleUploadAllFiles = async () => {
    for (const file of files) {
      await uploadFileInChunks(file);
    }
  };

  // Handle file selection and prepare for chunked upload
  const handleMultipleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);

    for (const file of selectedFiles) {
      await uploadFileInChunks(file);
    }
  };

  // Upload each file in chunks using mutation
  const uploadFileInChunks = async (file: File) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * CHUNK_SIZE;
      const end = Math.min(file.size, start + CHUNK_SIZE);
      const base64Chunk = await fileChunkToBase64(file, start, end);

      const fileChunk: FileFormat = {
        name: file.name,
        format: file.type,
        chunk: base64Chunk,
        chunkIndex: chunkIndex,
        totalChunks: totalChunks,
        ingesterType: IngesterType.KBA_INGESTER,
      };

      await mutate({ variables: { fileChunk } });

      const progress = Math.round(((chunkIndex + 1) / totalChunks) * 100);
      setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
    }

    // Mark the file as fully uploaded
    setUploadedFiles((prev) => [...prev, file]);
  };

  return (
    <>
      <div style={styles.container}>
        <Stack verticalAlign="center" horizontalAlign="center">
          <Stack.Item>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              multiple
              onChange={(e) => handleFiles(e.target.files)}
            />

            {/* Drag and Drop Area */}
            <div
              style={styles.dropArea}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <Stack verticalAlign="center" horizontalAlign="center">
                <Stack.Item>
                  <p>Drag & drop files</p>
                </Stack.Item>
                <Stack.Item>
                  <button
                    type="button"
                    onClick={handleFilePicker}
                    style={styles.button}
                  >
                    Choose Files
                  </button>
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            {files.length > 0 && (
              <div style={styles.fileList}>
                {files.map((file, index) => (
                  <Stack>
                    <Stack
                      horizontal
                      style={{ paddingBottom: 10, paddingTop: 10 }}
                    >
                      <Document20Regular />
                      {file.name}
                    </Stack>
                    <Stack horizontal>
                      <ProgressBar
                        thickness="large"
                        max={100}
                        value={uploadProgress[file.name] || 0}
                        style={{ marginTop: "9px" }}
                      />
                      <Text style={{ marginLeft: "5px" }}>
                        {uploadProgress[file.name] || 0}%
                      </Text>
                    </Stack>
                  </Stack>
                ))}
              </div>
            )}
          </Stack.Item>
          {/* Upload Button */}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            {files.length > 0 && (
              <Button
                onClick={handleUploadAllFiles}
                style={styles.uploadButton}
              >
                Start Upload
              </Button>
            )}
          </div>
        </Stack>
      </div>
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    marginLeft: "auto",
    marginRight: "auto",
  },
  dropArea: {
    width: "500px",
    height: "200px",
    border: "2px dashed #0F5F86",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    // backgroundColor: "#f9f9f9",
    color: "#0F5F86",
    marginBottom: "20px",
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#0F5F86",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  fileList: {
    width: "400px",
  },
};
