import { Stack } from "@fluentui/react";
import {
  Input,
  Field,
  CompoundButton,
  Button,
  Switch,
  Menu,
  MenuTrigger,
  Tooltip,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem as FMenuItem,
  Avatar,
} from "@fluentui/react-components";
import { Book20Regular, Settings20Regular } from "@fluentui/react-icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IVotedResponse } from "./VotedResponsesDisplay";
import { customTokens } from "../../../theme";
import { tags } from "../constants";
import { UploadModal } from "../UploadModal";
import { Tag } from "@fluentui/react-tags";

function MenuItem({
  votedResponse,
  setActiveVotedResponse,
  activeVotedResponse,
}: {
  votedResponse: IVotedResponse;
  setActiveVotedResponse: Dispatch<SetStateAction<IVotedResponse>>;
  activeVotedResponse: IVotedResponse;
}) {
  const [randomTags, setRandomTags] = useState<string[]>(
    tags.sort(() => 0.5 - Math.random()).slice(0, 3)
  );

  useEffect(() => {
    if (votedResponse.id === activeVotedResponse.id) {
      setRandomTags(activeVotedResponse.tags);
    }
  }, [activeVotedResponse, setActiveVotedResponse]);

  useEffect(() => {
    let art = { ...votedResponse };
    art.tags = randomTags;
    if (votedResponse.id === activeVotedResponse.id)
      setActiveVotedResponse(art);
  }, []);

  return (
    <Stack style={{ padding: "1px" }}>
      <CompoundButton
        icon={<Book20Regular />}
        // appearance="transparent"
        appearance={
          activeVotedResponse.id === votedResponse.id
            ? "primary"
            : "transparent"
        }
        style={{
          padding: "1px",
          // width: "80%",
          textAlign: "left",
          alignContent: "flex-start",
          display: "flex",
          justifyContent: "flex-start",
        }}
        size="small"
        secondaryContent={
          <>
            {!votedResponse.created.includes(".")
              ? votedResponse.created.replace("T", " ")
              : votedResponse.created
                  .replace("T", " ")
                  .substring(0, votedResponse.created.indexOf("."))}
            <Stack horizontal style={{ paddingTop: "5px" }}>
              {randomTags?.slice(0, 3).map((tag) => (
                <Tag
                  shape="circular"
                  size="small"
                  style={{ margin: "2px" }}
                  color="colorful"
                  media={<Avatar name={tag} color="colorful" />}
                >
                  {tag}
                </Tag>
              ))}
            </Stack>
          </>
        }
        onClick={() => {
          let art = { ...votedResponse };
          art.tags = randomTags;
          setActiveVotedResponse(art);
        }}
      >
        {votedResponse?.botAction.split(" ").slice(0, 6).join(" ")}
      </CompoundButton>
    </Stack>
  );
}

export function SideBarMenu({
  votedResponses: votedResponsesOff,
  setActiveVotedResponse,
  activeVotedResponse,
}: {
  votedResponses: IVotedResponse[];
  setActiveVotedResponse: Dispatch<SetStateAction<IVotedResponse>>;
  activeVotedResponse: IVotedResponse;
}) {
  const [votedResponses, setVotedResponses] =
    useState<IVotedResponse[]>(votedResponsesOff);

  const pageSize = 20;
  const f20 = votedResponses.slice(0, pageSize);
  // const totalPageCount = Math.ceil(votedResponses.length / pageSize);
  const [totalPageCount, setTotalPageCount] = useState<number>(
    Math.ceil(votedResponses.length / pageSize)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState(f20);
  const [disabledFilter, setDisabledFilter] = useState<boolean>(false);
  const [upVotedFilter, setUpVotedFilter] = useState<boolean>(false);
  const [allFilter, setAllFilter] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    const responses = votedResponsesOff.filter(
      (a: IVotedResponse) =>
        a.botAction.toLowerCase().includes(inputValue.toLowerCase()) ||
        a.created.toLowerCase().includes(inputValue.toLowerCase())
    );

    let i = responses.filter(
      (i: IVotedResponse) =>
        i.disabled == disabledFilter || i.upvoted == upVotedFilter
    );

    if (allFilter) {
      i = responses;
    }

    const startPoint = currentPage * pageSize - pageSize;
    const newOnes = i.slice(startPoint, startPoint + pageSize);
    setTotalPageCount(Math.ceil(i.length / pageSize));
    setCurrentItems(newOnes);
  }, [disabledFilter, upVotedFilter, allFilter]);

  useEffect(() => {
    const startPoint = currentPage * pageSize - pageSize;
    const newOnes = votedResponses.slice(startPoint, startPoint + pageSize);
    setCurrentItems(newOnes);
  }, [currentPage]);

  const items = currentItems.map((i: IVotedResponse) => {
    return (
      <MenuItem
        votedResponse={i}
        key={i.id}
        setActiveVotedResponse={setActiveVotedResponse}
        activeVotedResponse={activeVotedResponse}
      />
    );
  });

  return (
    <Stack
      style={{ minHeight: "88vh", maxHeight: "88vh" }}
      // verticalAlign="space-between"
    >
      <Stack.Item>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginBottom: "15px" }}
        >
          <Stack.Item>
            <Field>
              <Input
                placeholder="Filter"
                width={"100%"}
                style={{ background: customTokens.drawerInsideColor }}
                onChange={(e, d) => {
                  setInputValue(d.value);
                  const responses = votedResponsesOff.filter(
                    (a: IVotedResponse) =>
                      a.botAction
                        .toLowerCase()
                        .includes(d.value.toLowerCase()) ||
                      a.created.toLowerCase().includes(d.value.toLowerCase())
                  );

                  let i = responses.filter(
                    (i: IVotedResponse) =>
                      i.disabled == disabledFilter || i.upvoted == upVotedFilter
                  );

                  if (allFilter) i = responses;

                  setVotedResponses(i);
                  const startPoint = currentPage * pageSize - pageSize;
                  const newOnes = i.slice(startPoint, startPoint + pageSize);
                  setTotalPageCount(Math.ceil(i.length / pageSize));
                  setCurrentItems(newOnes);
                }}
              />
            </Field>
          </Stack.Item>
          <Stack.Item>{/* <UploadModal /> */}</Stack.Item>

          <Stack.Item>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Tooltip
                  content="With calendar icon and no contents"
                  relationship="label"
                >
                  <MenuButton
                    appearance="transparent"
                    icon={<Settings20Regular />}
                  />
                </Tooltip>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <FMenuItem>
                    <Switch
                      size={0.1}
                      label="All"
                      defaultChecked={allFilter}
                      checked={allFilter}
                      onChange={() => {
                        setAllFilter(!allFilter);
                        setDisabledFilter(false);
                        setUpVotedFilter(false);
                      }}
                    />
                  </FMenuItem>
                  <FMenuItem>
                    <Switch
                      label="Disabled"
                      checked={disabledFilter}
                      onChange={() => {
                        setDisabledFilter(!disabledFilter);
                        setAllFilter(false);
                      }}
                    />
                  </FMenuItem>
                  <FMenuItem>
                    <Switch
                      label="Up Voted"
                      checked={upVotedFilter}
                      onChange={() => {
                        setUpVotedFilter(!upVotedFilter);
                        setAllFilter(false);
                      }}
                    />
                  </FMenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </Stack.Item>
        </Stack>
        <Stack horizontal></Stack>
      </Stack.Item>
      <Stack.Item style={{ overflowY: "scroll", overflowX: "hidden" }}>
        {items}
      </Stack.Item>
      <Stack.Item style={{ alignSelf: "flex-end", marginTop: "auto" }}>
        <Stack horizontal>
          <Button
            appearance="transparent"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <Button appearance="transparent">{currentPage}</Button>
          <Button
            appearance="transparent"
            disabled={currentPage >= totalPageCount}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
